
:root {
  --black: #333333;
  --white: #f5f5f5;
  --backround: var(--white);
  --foreground: var(--black);
  --transition: 0.5s ease;
}
.switch {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 28px;
  }
.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: var(--foreground);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--foreground);
  }
  
  input:checked + .slider.enabled:before {
    -webkit-transform: translateX(36px);
    -ms-transform: translateX(36px);
    transform: translateX(36px);
  }
  .slider.enabled:before {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
  }
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin: 0 5px;
  }
  
  .icons svg {
    fill: var(--background);
    height: 30px;
    width: 30px;
    z-index: 0;
  }

  /* rer */
  #darkMode {
    background: transparent url("../../assets/images/moon.png") no-repeat center;
    background-size: 25px 25px;
    width: 35px;
    height: 35px;
    filter: grayscale(100%);
    border: none;
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out, filter 0.3s ease-in-out;
    background-color: black;
  }
  
  #darkMode:hover {
    filter: none;
    background-color: black;
    cursor: pointer;
  }
  #darkMode:focus {
    background-color: black;
  }
  
  #darkMode.clicked {
    filter: none !important;
    background-color: black;
  }