/* @tailwind base; */
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

:root {
    --main-bg-color-dark: #212529;
    --main-bg-color: #ffffff;
    --light-blue: #03a9f4;
    --border-color: #0288d147;
    --container-width: 500px;
    --text-white: #ffffff;
    --text-black: #000000;
  }


  body {
    /* font-family: 'Open Sans', sans-serif; */
    /* background: var(--main-bg-color); */
    /* color: var(--text-color); */
    /* background-color: #212529; */
  }

  a, a:visited{
    text-decoration: none;
    cursor: pointer!important;
}

li{
  list-style: none;
  margin-right: 1rem;
}
ul{
  display: flex;
  justify-content: flex-start;
}
  .logo {
    /* position: absolute;
    top: 10px;
    left: 10px;
    width: 150px; */
    /* filter: invert(1); */
  }
  
  .image-vector {
    width: 50vw;
    height: 50vh;
    /* background: url(/undraw-upload.svg) no-repeat center; */
    background-size: contain;
  }
  
  .upload-container,
  .drop-zone {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .upload-container,
  .drop-zone {
    flex-direction: column;
  }
  
  .upload-container {
    background: #ffffff;
    border-radius: 25px;
    box-shadow: 0px 20px 20px 0px #00000017;
  }
  
  .drop-zone {
    width: var(--container-width);
    min-height: 200px;
    border: 2px dashed var(--border-color);
    border-radius: 10px;
    margin: 30px;
    transition: 0.2s all ease-in;
  }
  
  /* will be added when user drags */
  .drop-zone.dragged {
    background: var(--main-bg-color);
    border-color: #0288d1;
  }
  
  .drop-zone input {
    display: none;
  }
  
  .icon-container {
    position: relative;
    width: 75px;
    height: 100px;
  }
  
  .icon-container img {
    width: 75px;
    position: absolute;
    transition: transform 0.25s ease-in-out;
    transform-origin: bottom;
  }
  
  .icon-container .center {
    z-index: 10;
  }
  .icon-container .right,
  .icon-container .left {
    filter: grayscale(0.5);
    transform: scale(0.9);
  }
  
  .dragged .center {
    transform: translateY(-5px);
  }
  .dragged .right {
    transform: rotate(10deg) scale(0.9) translateX(20px);
  }
  .dragged .left {
    transform: rotate(-10deg) scale(0.9) translateX(-20px);
  }
  
  .title {
    font-size: large;
  }
  
  #browseBtn {
    color: #2196f3;
    cursor: pointer;
  }
  
/* Custom file chooser */
.customFileChoose{
  display: flex;
  background: white;
  height: 240px;
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
}
/* Custom file chooser */


  /* uploading progress styles */
  .progress-container {
    border: 2px solid var(--main-bg-color);
    width: var(--container-width);
    height: 70px;
    border-radius: 10px;
    margin-bottom: 25px;
    position: relative;
    display: none;
  }
  
  .progress-container .inner-container {
    margin: 10px 15px;
    z-index: 2;
    position: absolute;
    width: calc(100% - 30px);
  }
  
  .progress-container .percent-container {
    font-size: 14px;
    margin: 5px;
    opacity: 0.7;
  }
  
  .progress-container .bg-progress {
    position: absolute;
    background: var(--main-bg-color);
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: transform 250ms linear;
    transform: scaleX(0);
    transform-origin: left;
  }
  
  .progress-container .progress-bar {
    width: 100%;
    height: 3px;
    border-radius: 2px;
    background: #03a9f4;
    transition: transform 200ms linear;
    transform: scaleX(0);
    transform-origin: left;
  }
  
  .toast {
    position: absolute;
    bottom: 10px;
    right: 50%;
    transform: translate(50%, 60px);
    padding: 10px 20px;
    background: var(--light-blue);
    color: #fff;
    border-radius: 5px;
    font-size: 18px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    transition: transform ease-in-out 0.2s;
  }
  
  .show.toast {
    transform: translate(50%, 0);
  }
/*   
  @media screen and (max-width: 900px) {
    :root {
      --container-width: 320px;
    }
    .image-vector {
      display: none;
    }
    .email-container .filed {
      flex-direction: column;
    }
    .email-container .filed {
      width: 300px;
    }
  }
   */
.singlePreview{
  max-width: 95px;
  border: 1px solid #454545;
}
.originalFileName{
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  white-space: nowrap;
  border-top: 1px solid #000000;
  text-align: center;
}
.files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;
  padding: 120px 0px 100px 35%;
  text-align: center !important;
  margin: 0;
  display: hidden;
  width: 100% !important;
  position: absolute;
}

.files input:focus{ 
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;
  border:1px solid #92b0b3;
}
.files{ 
  position:relative;
}
.uploadlogo {
  pointer-events: none;
  position: absolute;
  top: 40px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}
/* .files:after {  
    pointer-events: none;
    position: absolute;
    top: 60px;
    padding-bottom: 20px;
    left: 0;
    width: 50px;
    right: 0;
    height: 56px;
    content: "";
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
} */
.color input{ background-color:#f1f1f1;}

.files:before {
    position: absolute;
    bottom: 10px;
    left: 0;  pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: " or drag it here. ";
    display: block;
    margin: 0 auto;
    color: var(--light-blue);
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
}
.button {
  min-width: 170px;
  min-height: 42px;
  padding: 0 14px;
  font-size: 1rem;
  color: white;
  background-color: #F1592A;
  border-color: #F1592A;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  border-radius: 4px;
  border: 2px solid;
  border-color: transparent;
  font-weight: 500;
  line-height: 1;
  user-select: none;
  touch-action: manipulation;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 20%), 0 4px 6px -2px rgb(0 0 0 / 5%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: background-color 160ms, color 160ms, box-shadow 160ms, border 160ms;
  margin: 1rem 0;
}

.button:hover {
  background-color: #ec6b43;
}


/* Supported File */
.upload-area__tooltip {
  position: relative;
  color: slateblue;
  cursor: pointer;
  transition: color 300ms ease-in-out;
}

.upload-area__tooltip:hover, .upload-area__tooltip:focus  {
  background-color: black;
}

.upload-area__tooltip-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -125%);
  min-width: max-content;
  background-color: black;
  color: var(--clr-blue);
  border: 1px solid var(--clr-light-blue);
  padding: 0.625rem 1.25rem;
  font-weight: 500;
  opacity: 0;
  visibility: hidden;
  transition: none 300ms ease-in-out;
  transition-property: opacity, visibility;
}

.upload-area__tooltip:hover .upload-area__tooltip-data, .upload-area__tooltip:focus .upload-area__tooltip-data {
  opacity: 1;
  visibility: visible;
}

.round-dimension{
  width: 50px;
  height: 50px;
  
}
/* Supported File */
/* .navbar-nav{
  background-color: white;
} */
/* @media (prefers-color-scheme: dark) {
  body.dark {
    background: var(--main-bg-color-dark);
    color: var(--text-white);
    --logo-color: invert(0);
    --nav-bg-color: var(--main-bg-color-dark);
  }
  body.light {
    background: var(--main-bg-color);
    color: var(--text-black);
    --logo-color: invert(1);
    --nav-bg-color: white;
  }
  .logo {
    filter: var(--logo-color);
  }
  .fabIcon{
    color: var(--text-white);
  }
  .navbar-nav{
    background-color: var(--nav-bg-color)
  }

}

@media (prefers-color-scheme: light) {
  body.light {
    background: var(--main-bg-color);
    color: var(--text-black);
    --logo-color: invert(1);
    --nav-bg-color: white;
  }
  body.dark {
    background: var(--main-bg-color-dark);
    color: var(--text-white);
    --logo-color: invert(0);
    --nav-bg-color: var(--main-bg-color-dark);
  }
  .logo {
    filter: var(--logo-color);
  }
 
  .navbar-nav{
    background-color: var(--nav-bg-color)
  }
}


 */
